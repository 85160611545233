.title {
    font-family: var(--RM);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: normal;
    color: var(--darkBlue-700);
    padding: 0;
    margin: 0;
}
