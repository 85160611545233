.table {
    padding: 0;
    margin: 0;
    width: 100%;
}
.name {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    height: 56px;
    text-align: start;
    background-color: transparent;
    border-left: solid 1px var(--primary-400-base);
    padding: 0;
    margin: 0;
    padding-left: 25px;
}

.status {
    width: 150px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    height: 56px;
    text-align: start;
    background-color: transparent;
}

.statusName {
    text-transform: capitalize;
}

.icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.link {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    margin: 0;
    margin-left: 14px;
}

.link:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}
