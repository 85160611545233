.card {
    width: 281px;
    min-height: 284px;
    background-color: var(--whitebase);
    padding-left: 30px;
    margin-right: 5px;
    margin-left: 5px;
    padding-bottom: 39px;
    padding-top: 20px;
    padding-right: 22px;
    margin-bottom: 76px;
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}
.header {
    padding: 0;
    margin: 0;
    text-align: center;
}

.statistic {
    padding: 0;
    margin: 0;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-400-base);
}

.title {
    font-family: Raleway, sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
    border-radius: 4px;
    outline: none;
}

.title:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.table {
    margin-top: 6px;
    width: 100%;
}

.cellName {
    height: 28px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
}

.paramCell {
    width: 30px;
    height: 28px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: right;
    color: var(--black-base);
}
