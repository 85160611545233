.wrapper {
    width: 283px;
    margin: 0;
    padding: 0;
    padding: 2px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.label {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    padding: 0;
}
