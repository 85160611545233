.wrapper {
    margin: 0;
    padding: 0;
    max-height: 200px;
    min-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid var(--darkBlue-300);
    border-radius: 3px;
    background-color: var(--white);
    overflow-y: auto;
    position: relative;
}
