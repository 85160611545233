.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.navigationWrapper {
    margin-top: 47px;
}

.paginationWrapper {
    margin-top: 67px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
