.main {
    margin-top: auto;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
}

.wrapper {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.title {
    font-family: Raleway, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-400-base);
    padding: 0;
    margin: 0;
}

.signatureWrapper {
    width: 88px;
    height: 26px;
    border-radius: 4px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--whitebase);
    position: relative;
    overflow: hidden;
}

.signature {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
}
