.button {
    font-family: Raleway, sans-serif;
    padding: 8px 16.5px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    border-radius: 4px;
    border: solid 2px var(--secondary-400-base);
    background-color: var(--secondary-400-base);
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    color: var(--whitebase);
}

.button:disabled {
    color: var(--black-100);
    border-color: var(--black-100);
    background-color: transparent;
    cursor: auto;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:active {
    background-color: var(--secondary-500);
    border-color: var(--secondary-500);
}
