.wrapper {
    width: 481px;
    height: 86vh;
    overflow-y: auto;
}
.content {
    width: 100%;
    min-height: 500px;
    background-color: var(--whitebase);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.imageWrapper {
    width: 100%;
    height: 250px;
    overflow: hidden;
    background-color: var(--tertiary-200);
    position: relative;
}

.image {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.marker {
    z-index: 1;
    position: absolute;
    top: 0;
    left: calc(50% - 50px);
    border-left: 51px solid transparent;
    border-right: 51px solid transparent;
    border-top: 102px solid rgba(0, 0, 0, 0.3);
}

.marker::after {
    content: "";
    top: -102px;
    left: -43px;
    position: absolute;
    border-left: 43px solid transparent;
    border-right: 43px solid transparent;
    border-top: 87px solid var(--error-400-base);
}

.marker::before {
    content: "";
    top: -102px;
    left: -47px;
    position: absolute;
    border-left: 47px solid transparent;
    border-right: 47px solid transparent;
    border-top: 94px solid var(--whitebase);
}

.markerIcon {
    position: absolute;
    width: 29px;
    height: 29px;
    z-index: 2;
    top: -90px;
    left: -12px;
}

.main {
    position: relative;
    padding: 0 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.numberWrapper {
    position: absolute;
    top: -32px;
    left: calc(50% - 33.5px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: solid 3px var(--whitebase);
    background-color: var(--error-400-base);
    display: flex;
    justify-content: center;
    align-items: center;
}

.number {
    font-family: Raleway, sans-serif;
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 37px;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
    padding: 0;
    margin: 0;
    padding-bottom: 7px;
}

.title {
    max-width: 300px;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    font-family: Raleway, sans-serif;
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
}

.text {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
    margin: 0;
    padding: 0;
}

.userRole {
    text-transform: capitalize;
    max-width: 300px;
    font-family: Raleway, sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
    margin: 0;
    padding: 0;
}
.signatureWrapper {
    margin-top: 7px;
}

.footer {
    margin-top: 39px;
    margin-bottom: 37px;
    padding: 0 38px;
    display: flex;
    justify-content: space-between;
}
