.item {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    color: var(--darkBlue-500);
    cursor: pointer;
    min-height: 34px;
    display: flex;
    align-items: center;
    padding-right: 14px;
    padding-left: 14px;
}

.item--selected {
    background-color: var(--grey-200);
}
.item:hover {
    background-color: var(--grey-300);
}
.item:active {
    background-color: var(--grey-400);
}
