.wrapper {
    margin-top: 47px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.selectWrapper {
    width: 220px;
}

.ButtonWrapper {
    height: 38px;
    display: flex;
    align-items: center;
}
