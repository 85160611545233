:root {
    --retina-2x: "(min-resolution: 144dpi), (min-resolution: 1.5dppx)";
    --RM: "Roboto Medium", sans-serif;
    --RB: "Roboto Bold", sans-serif;

    --blue-400: hsl(200, 80%, 80%);
    --blue-500: hsl(200, 97%, 48%);
    --blue-600: hsl(200, 97%, 38%);
    --blue-700: hsl(216, 100%, 40%);

    --green-400: hsl(154, 50%, 75%);
    --green-600: hsl(154, 71%, 44%);
    --green-700: hsl(154, 71%, 34%);

    --white: hsl(0, 0%, 100%);

    --red-400: hsl(0, 70%, 85%);
    --red-700: hsl(0, 94%, 65%);

    --purple-400: hsl(288, 41%, 90%);
    --purple-700: hsl(288, 61%, 70%);

    --orange-400: hsl(36, 75%, 75%);
    --orange-700: hsl(36, 98%, 54%);

    --yellow-400: hsl(46, 70%, 80%);
    --yellow-700: hsl(46, 90%, 62%);

    --grey-100: hsl(240, 9%, 98%);
    --grey-200: hsl(220, 16%, 96%);
    --grey-300: hsl(240, 2%, 92%);
    --grey-400: hsl(240, 3%, 85%);
    --grey-500: hsl(0, 0%, 77%);
    --grey-600: hsl(0, 0%, 44%);

    --darkBlue-300: hsl(223, 12%, 89%);
    --darkBlue-400: hsl(214, 17%, 62%);
    --darkBlue-500: hsl(219, 13%, 48%);
    --darkBlue-600: hsl(219, 13%, 38%);
    --darkBlue-700: hsl(211, 22%, 26%);

    --form-wrapper-width: 600px;
}
