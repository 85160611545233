.wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 242px;
    border: dashed 1px var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.icon {
    margin-top: 32px;
    width: 56px;
    height: 56px;
    fill: var(--primary-400-base);
}

.title {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
    margin: 0;
    border-top: 8px;
    margin-bottom: 30.5px;
}
