.checkBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.input {
    display: none;
}

.icon {
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid var(--darkBlue-500);
    box-sizing: border-box;
    border-radius: 3px;
    fill: transparent;
}

.input:checked ~ .icon {
    fill: var(--white);
    background-color: var(--blue-500);
    border-color: var(--blue-500);
}

.input:disabled ~ .icon {
    cursor: auto;
}

.text {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    color: var(--darkBlue-500);
}
