.button {
    border: none;
    padding: 3px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    cursor: pointer;
}

.text {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
}

.arrow {
    margin-left: 4px;
    height: 8px;
    border-top: solid 8px var(--black-base);
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}

.arrowUp {
    transform: rotate(180deg);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:disabled .arrow {
    cursor: auto;
    border-color: transparent;
}

.button:active .text {
    color: var(--black-300);
}
