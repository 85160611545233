:root {
    --whitebase: #ffffff;

    --black-100: #e6e6e6;
    --black-200: #939393;
    --black-300: #666666;
    --black-base: #212121;

    --primary-700: #0f4880;
    --primary-600: #125699;
    --primary-500: #1873cc;
    --primary-400-base: #1e90ff;
    --primary-300: #62b1ff;
    --primary-200: #a5d3ff;
    --primary-100: #e9f4ff;

    --secondary-700: #330a1f;
    --secondary-600: #66153e;
    --secondary-400-base: #ff349b;
    --secondary-500: #b3246d;
    --secondary-300: #ff71b9;
    --secondary-200: #ffaed7;
    --secondary-100: #ffebf5;

    --tertiary-400-base: #5540fb;
    --tertiary-300: #8879fc;
    --tertiary-200: #bbb3fd;
    --tertiary-100: #ccc6fe;
    --tertiary-500: #3b2db0;
    --tertiary-600: #221a64;
    --tertiary-700: #110d32;

    --success-100: #d1eedd;
    --success-200: #a3ddbc;
    --success-300: #5dc389;
    --success-400-base: #18a957;
    --success-500: #11763d;
    --success-600: #0a4423;
    --success-700: #052211;

    --warning-100: #fff1d7;
    --warning-200: #ffe4af;
    --warning-300: #ffcf74;
    --warning-400-base: #ff8a38;
    --warning-500: #b38327;
    --warning-600: #664b16;
    --warning-700: #33250b;

    --error-100: #f9d0d9;
    --error-200: #f2a2b3;
    --error-300: #e95c7b;
    --error-400-base: #df1642;
    --error-500: #9c0f2e;
    --error-600: #59091a;
    --error-700: #2d040d;

    --whitebase-10: rgba(255, 255, 255, 0.1);
    --whitebase-20: rgba(255, 255, 255, 0.2);
    --black-30: rgba(0, 0, 0, 0.3);
    --whitebase-30: rgba(255, 255, 255, 0.3);
}

body,
html {
    margin: 0;
    font-family: "Raleway", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

.passport td {
    border: 1px solid gray;
    padding: 5px;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

    justify-content: center;
    align-items: center;
}

.signature-wrapper {
    width: 150px;
    height: 75px;
}

.student-statuses td {
    border: 1px lightsalmon solid;
    padding: 5px;
}

.dashboard .card {
    background: #ededed;
    padding: 20px;
}

.dashboard .card header {
    width: 150px;
    display: inline-block;
}
