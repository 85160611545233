.button {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: relative;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.icon {
    position: absolute;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    border: solid var(--primary-400-base) 2px;
    border-top-color: transparent;
    border-left-color: transparent;
    top: calc(50% - 4px);
    left: calc(50% - 5px);
}

.iconOpen {
    transform: rotate(45deg);
    top: calc(50% - 5px);
    left: calc(50% - 4px);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:active .icon {
    border-color: var(--primary-300);
    border-top-color: transparent;
    border-left-color: transparent;
}

.button:disabled {
    cursor: auto;
}

.button:disabled .icon {
    border-color: var(--black-100);
    border-top-color: transparent;
    border-left-color: transparent;
}
