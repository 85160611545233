.wrapper {
    margin: 0;
    display: flex;
    align-self: center;
}

.link {
    margin: 0;
    padding: 0;
    background-color: var(--white);
    text-decoration: none;
    min-width: 28px;
    height: 28px;
    border: 1px solid var(--grey-500);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    border-radius: 3px;
}

.ButtonLeft::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border: 1px solid var(--grey-500);
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);
    top: calc(50% - 4px);
    left: calc(50% - 4px);
}

.ButtonRight::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border: 1px solid var(--grey-500);
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
    top: calc(50% - 4px);
    left: calc(50% - 4px);
}

.text {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
    color: var(--grey-400);
    mix-blend-mode: normal;
    border-radius: 3px;
}

.linkActive {
    background-color: var(--grey-200);
}
.linkActive .text {
    color: var(--darkBlue-700);
}

.link:hover .text {
    color: var(--darkBlue-700);
}

.link:focus {
    border-color: var(--darkBlue-500);
}

.link:active {
    background-color: var(--grey-200);
}
.link:active .text {
    color: var(--darkBlue-700);
}

.ButtonLeft:hover::before {
    border-color: var(--darkBlue-700);
    border-top-color: transparent;
    border-right-color: transparent;
}

.ButtonLeft:active::before {
    border-color: var(--darkBlue-700);
    border-top-color: transparent;
    border-right-color: transparent;
}

.ButtonRight:hover::before {
    border-color: var(--darkBlue-700);
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.ButtonRight:active::before {
    border-color: var(--darkBlue-700);
    border-bottom-color: transparent;
    border-left-color: transparent;
}
