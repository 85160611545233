.blue {
    border-color: var(--blue-500);
    background-color: var(--blue-500);
    color: var(--white);
}

.blue:hover {
    background-color: var(--blue-600);
}
.blue:active {
    background-color: var(--blue-600);
}
.blue:focus {
    box-shadow: 0 0 0 2px var(--darkBlue-400);
}
