.wrapper {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.title {
    padding: 0;
    margin: 0;
    font-family: var(--RB);
    font-weight: 300;
    line-height: 14px;
    font-size: 12px;
    color: var(--darkBlue-500);
    position: relative;
    display: block;
    align-self: flex-start;
}

.title--required::before {
    content: "*";
    position: absolute;
    display: block;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 14px;
    text-align: center;
    color: var(--red-700);
    right: -7px;
    top: -4px;
}

.listWrapper {
    padding: 0;
    margin-top: 48px;
    height: auto;
    position: absolute;
    width: 100%;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
}
