.input {
    border: 1px solid var(--darkBlue-300);
    background-color: var(--grey-100);
    box-sizing: border-box;
    border-radius: 3px;
    height: 32px;
    width: 100%;
    margin-top: 0;
    padding-left: 9px;
    padding-right: 25px;
    outline: none;
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 32px;
    font-weight: 300;
    text-align: left;
    color: var(--darkBlue-500);
}
