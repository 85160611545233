.text {
    margin-left: auto;
    margin-right: 12px;
    color: var(--darkBlue-400);
    font-family: Raleway, sans-serif;
    font-size: 14px;
}

.selectText {
    width: 65px;
}

.selectorWrapper {
    width: 65px;
}
