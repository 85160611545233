.label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    margin-bottom: 17px;
}

.title {
    padding: 0;
    margin: 0;
    font-family: var(--RB);
    font-weight: 300;
    line-height: 14px;
    font-size: 12px;
    color: var(--darkBlue-500);
    position: relative;
    display: block;
}

.error {
    display: flex;
    line-height: 20px;
    font-size: 14px;
    font-family: Raleway, sans-serif;
    font-weight: 400;
    color: var(--red-700);
    text-align: right;
    width: 100%;
    height: 20px;
    background-color: transparent;
    border-radius: 4px;
    bottom: -26px;
    right: 0;
    padding: 0;
    box-sizing: border-box;
    margin-top: 5px;
}

.required::before {
    content: "*";
    position: absolute;
    display: block;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 14px;
    text-align: center;
    color: var(--red-700);
    right: -7px;
    top: -4px;
}
