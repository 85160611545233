/* popup */

.popup {
    display: flex;
    align-items: stretch;
    background-color: var(--whitebase);
    padding: 0;
    position: relative;
    top: calc(50% - 150px);
    left: calc(50% - 350px);
    width: 800px;
    min-height: 339px;
}

.iconWrapper {
    position: relative;
    width: 64px;
    background-color: var(--primary-400-base);
}

.icon {
    position: absolute;
    top: 35px;
    left: calc(50% - 19.5px);
    fill: var(--whitebase);
}

.body {
    padding-top: 64px;
    padding-bottom: 32px;
    padding-right: 64px;
    padding-left: 66px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.title {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 52px;
    color: var(--black-base);
    margin: 0;
    padding: 0;
    max-width: 355px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text {
    display: flex;
    align-items: center;
    font-family: "Raleway", sans-serif;
    font-size: 21px;
    line-height: 32px;
    text-align: start;
    color: var(--black-base);
    padding: 0;
    margin: 0;
    margin-top: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

/* end popup */

/* ----popupButton------------- */

.button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 16px;
    border: 2px solid var(--primary-400-base);
    border-radius: 4px;
    height: 40px;
    background-color: var(--whitebase);
    color: var(--primary-400-base);
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.button:active {
    background-color: var(--black-100);
}

.button:disabled {
    background-color: var(--black-100);
    color: var(--primary-200);
    cursor: auto;
    border-color: var(--black-100);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.primary {
    background-color: var(--primary-400-base);
    color: var(--whitebase);
}

.primary:active {
    background-color: var(--primary-300);
}

.secondary {
    background-color: var(--secondary-400-base);
    border-color: var(--secondary-400-base);
    color: var(--whitebase);
}

.secondary:active {
    background-color: var(--secondary-300);
    border-color: var(--secondary-300);
}
/* ----end popupButton------------- */
