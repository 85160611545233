.wrapper {
    position: relative;
    width: 20px;
}

.button {
    width: 17px;
    height: 17px;
    border-radius: 3px;
    border: none;
    padding: 2px;
    background-color: inherit;
    cursor: pointer;
    outline: none;
    position: relative;
    transform: rotate(90deg);
    display: flex;
    justify-content: center;
    align-content: center;
}

.buttonIcon {
    fill: var(--darkBlue-400);
    width: 13px;
    height: 13px;
}

.button:hover .buttonIcon {
    fill: var(--grey-500);
}

.button:active .buttonIcon {
    fill: var(--grey-500);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--blue-500);
}

.button:focus .buttonIcon {
    fill: var(--darkBlue-700);
}

.button--horizontal {
    width: 22px;
    height: 22px;
}

.button--horizontal .buttonIcon {
    transform: rotate(90deg);
    width: 20px;
    height: 18px;
}

.menu {
    display: none;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border: solid 1px var(--grey-300);
    border-radius: 2px;
    min-width: 120px;
    background-color: var(--white);
    box-sizing: border-box;
    padding-top: 4px;
    padding-bottom: 10px;
    top: -5px;
    right: 32px;
    z-index: 50;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
}

.menu--open {
    display: block;
}

.menu::before {
    content: "";
    position: absolute;
    top: 5px;
    right: -8px;
    transform: rotate(45deg);
    display: block;
    width: 15px;
    height: 15px;
    border: solid 1px var(--grey-300);
    background-color: var(--white);
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: -1;
}

.button--horizontal ~ .menu {
    top: 34px;
    right: -7px;
}

.button--horizontal ~ .menu::before {
    border: solid 1px var(--grey-300);
    border-bottom-color: transparent;
    border-right-color: transparent;
    top: -8px;
    right: 8px;
}

.menu--right ~ .menu {
    right: auto;
    left: 28px;
}

.menu--right ~ .menu::before {
    border: solid 1px var(--grey-300);
    border-top-color: transparent;
    border-right-color: transparent;
    right: auto;
    left: -7px;
}

.menuLink {
    padding-left: 18px;
    padding-right: 18px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 11px;
    color: var(--darkBlue-700);
    text-decoration: none;
    height: 32px;
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.menuIcon {
    fill: var(--darkBlue-400);
    margin-right: 10px;
}

.menuItem:hover .menuLink {
    background-color: var(--grey-200);
    color: var(--darkBlue-700);
}
