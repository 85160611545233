.header {
    margin-bottom: 62px;
}

.dashboard {
    margin-top: 79px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
