.cell {
    height: 29px;
    min-width: 120px;
    white-space: nowrap;
    border-bottom: 2px solid var(--darkBlue-300);
    font-family: Raleway, sans-serif;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    color: var(--darkBlue-500);
    vertical-align: middle;
    text-align: left;
    padding: 0 10px;
}
.cell--active {
    background-color: var(--grey-200);
}

.button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-family: Raleway, sans-serif;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    color: var(--darkBlue-500);
    border: 1px solid transparent;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
    outline: none;
}

.button--center {
    justify-content: center;
}
.button:not(:disabled):hover {
    color: var(--darkBlue-600);
}
.button:not(:disabled):hover .icon {
    fill: var(--darkBlue-600);
}

.button:active {
    color: var(--darkBlue-600);
}
.button:active .icon {
    fill: var(--darkBlue-600);
}

.button:focus {
    color: var(--darkBlue-700);
}

.button:disabled {
    cursor: auto;
}

.icon {
    display: none;
    fill: var(--darkBlue-500);
    margin-left: 5px;
    width: 11px;
    height: 7px;
}

.button--up .icon {
    display: block;
}

.button--down .icon {
    display: block;
    transform: rotate(180deg) translateY(-2px);
}
