.wrapper {
    width: 100%;
    display: flex;
}

.link {
    width: 33%;
    background-color: transparent;
    border-bottom: solid 2px var(--black-100);
    min-height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 7px;
    outline: none;
}

.link:focus {
    /* border-bottom-color: var(--secondary-400-base); */
    background-color: var(--grey-100);
}

.active {
    border-bottom-color: var(--primary-400-base);
}

.text {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
}

.text:first-letter {
    text-transform: uppercase;
}

.icon {
    fill: var(--primary-400-base);
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
