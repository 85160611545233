.green {
    border-color: var(--green-600);
    background-color: var(--green-600);
    color: var(--white);
}
.green:hover {
    background-color: var(--green-700);
}
.green:active {
    background-color: var(--green-700);
}
.green:focus {
    box-shadow: 0 0 0 2px var(--green-400);
}
