.table__line:hover .table__cell {
    background-color: var(--grey-200);
}

.table__line:last-child .table__cell {
    border-bottom: 2px solid var(--darkBlue-300);
}

.table__line:last-child .table__cell:last-child ul {
    top: auto;
    bottom: 0;
}

th.table__cell {
    height: 29px;
    min-width: 120px;
    white-space: nowrap;
    border-bottom: 2px solid var(--darkBlue-300);
    cursor: auto;
}
th.table__cell--w20 {
    width: 20px;
    min-width: 20px;
}

.table__cell {
    font-family: Raleway, sans-serif;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    color: var(--darkBlue-500);
    vertical-align: middle;
    text-align: left;
    height: 50px;
    padding: 0 10px;
    cursor: pointer;
}

.table__cell--settings-select {
    border-bottom: 2px solid var(--darkBlue-300);
    background-color: var(--grey-200);
    cursor: auto;
}
.table__cell--settings-select .table__cell-wrapper {
    justify-content: flex-start;
}
.table__cell--settings-select .table__cell-wrapper .table__text {
    margin-right: 35px;
}

.table__cell--settings-select .table__cell-wrapper {
    justify-content: flex-start;
}
.table__cell--settings-select .table__cell-wrapper .table__text {
    margin-right: 35px;
}

.table__cell-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    position: relative;
}

.table__settings-select-btn-wrapper {
    margin-left: 20px;
    margin-right: 10px;
}
.table__settings-select-btn-wrapper button:not(:last-child) {
    margin-right: 10px;
}
