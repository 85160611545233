button.btn--transparent {
    background-color: transparent;
    color: var(--blue-500);
    border-color: transparent;
}
button.btn--transparent:hover {
    background-color: var(--grey-200);
}
button.btn--transparent:active {
    border-color: var(--grey-500);
}
button.btn--transparent:focus {
    box-shadow: 0 0 0 2px var(--blue-500);
}
