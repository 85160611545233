.ellipseCloseButton {
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    display: block;
    background-color: transparent;
    font-size: 20px;
    line-height: normal;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.closeButton {
    position: absolute;
    right: 5px;
    top: calc(50% - 8px);
}

.ellipseCloseButtonIcon {
    width: 16px;
    height: 16px;
    fill: var(--grey-500);
    transition-duration: 0.3s;
}

.ellipseCloseButtonText {
    display: none;
}
