.button {
    font-family: Raleway, sans-serif;
    width: 88px;
    height: 26px;
    box-sizing: border-box;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 4px;
    border: solid 2px var(--secondary-400-base);
    background-color: var(--secondary-400-base);
    font-size: 14px;
    font-stretch: normal;
    line-height: normal;
    cursor: pointer;
    color: var(--whitebase);
}

.button:disabled {
    color: var(--black-100);
    border-color: var(--black-100);
    background-color: transparent;
    cursor: auto;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:active {
    background-color: var(--secondary-500);
    border-color: var(--secondary-500);
}
