.button {
    border: 1px solid var(--darkBlue-300);
    border-radius: 3px;
    min-height: 32px;
    padding-left: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-top: 0;
    background-color: var(--grey-100);
    cursor: pointer;
    padding-right: 28px;
    position: relative;
    outline: none;
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    color: var(--darkBlue-500);
}

.button:disabled {
    cursor: auto;
}

.button:focus {
    box-shadow: 0 0 0 1px var(--blue-500);
    border: solid 1px var(--blue-500);
}

.icon {
    fill: var(--darkBlue-400);
    position: absolute;
    right: 10px;
    top: calc(50% - 8.5px);
    transition-duration: 0.3s;
}
.isOpen {
    box-shadow: 0 0 0 1px var(--blue-500);
    border: solid 1px var(--blue-500);
    background-color: transparent;
}

.isClose {
    background-color: var(--grey-100);
}
.isClose .icon {
    transform: rotate(180deg);
}
