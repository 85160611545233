.tableWrapper {
    width: 100%;
    margin-top: 20px;
    position: relative;
}
.table {
    width: 100%;
}

.headerCell {
    background-color: var(--primary-100);
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    height: 56px;
    text-align: start;
}
.headerCell:first-child {
    padding-left: 40px;
}
