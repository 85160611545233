.filterSubMenu {
    position: absolute;
    width: auto;
    min-width: 250px;
    z-index: 15;
    height: auto;
    padding: 24px;
    border-radius: 3px;
    border: 1px solid var(--darkBlue-300);
    background-color: var(--white);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
    display: block;
}

.filterSubMenuRightPosition {
    right: 0;
}

.filterSubMenuWrapper {
    margin: 0;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 312px;
}

.searchWrapper {
    box-sizing: border-box;
    position: relative;
    padding: 5px 10px 9px;
    overflow: hidden;
}

.restoreDefault {
    padding: 0;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
    border: none;
    padding-bottom: 0;
    font-family: Raleway, sans-serif;
    width: 100%;
    display: block;
    text-align: right;
}

.filterSubMenuListTitle {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 11px;
    margin-bottom: 0;
    border: none;
    padding-bottom: 0;
    font-family: Raleway, sans-serif;
}

.filterSubMenuList {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    list-style: none;
}

.filterSubMenuCheckBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.checkBoxInput {
    display: none;
}

.checkBoxIcon {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--darkBlue-300);
    box-sizing: border-box;
    border-radius: 3px;
    fill: transparent;
    margin-right: 10px;
}

.checkBoxInput:checked ~ .checkBoxIcon {
    fill: var(--white);
    background-color: var(--blue-500);
    border-color: var(--blue-500);
}

.checkBoxText {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 14px;
    color: var(--darkBlue-500);
}

.filterSubMenuItem {
    min-height: 32px;
    padding: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    align-items: stretch;
}

.filterSubMenuItemSelected {
    background-color: var(--grey-100);
}

filterSubMenuItem:hover {
    background-color: var(--grey-100);
}

filterSubMenuItem:active {
    background-color: rgb(195, 198, 201);
}
