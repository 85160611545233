.wrapper {
    width: 529px;
    height: 48px;
    margin: 0;
    padding: 0;
    position: relative;
}

.input {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 52px;
    padding-left: 16px;
    border-radius: 6px;
    border: solid 1px var(--grey-300);
    outline: none;
    font-family: Raleway, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: var(--black-base);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.input::placeholder {
    font-family: Raleway, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: var(--black-200);
}

.button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 48px;
    height: 48px;
    border-radius: 0 6px 6px 0;
    border: solid 1px var(--secondary-400-base);
    background-color: var(--secondary-400-base);
    cursor: pointer;
    outline: none;
}

.button:disabled {
    cursor: auto;
    background-color: var(--secondary-300);
    border-color: var(--secondary-300);
}

.icon {
    fill: var(--white);
}
