.cell {
    height: 56px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
}

.cellPl {
    padding-left: 30px;
}

.cellMilestone {
    width: 150px;
}
.cellName {
    min-width: 420px;
}

.name {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    margin: 0;
    margin-left: 14px;
}
.nameWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.name:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.openedCell:first-child {
    padding-left: 40px;
}

.milestoneWrapper {
    display: flex;
}

.link {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    margin: 0;
    margin-left: 14px;
}

.link:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}
