.gray {
    border-color: var(--darkBlue-500);
    background-color: var(--darkBlue-500);
    color: var(--white);
}
.gray:hover {
    background-color: var(--darkBlue-600);
}
.gray:active {
    background-color: var(--darkBlue-600);
}
.gray:focus {
    box-shadow: 0 0 0 2px var(--darkBlue-400);
}
