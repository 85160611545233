.wrapper {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    color: var(--darkBlue-700);
}

.countStart {
    font-family: var(--RB);
}

.countEnd {
    font-family: var(--RB);
}

.countTotal {
    font-family: var(--RB);
}
