.button {
    padding: 0;
    width: 80px;
    height: 28px;
    border-radius: 3px;
    border: solid 1px transparent;
    position: relative;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.blue {
    border-color: var(--blue-500);
    background-color: var(--blue-500);
    color: var(--white);
}

.blue:hover {
    background-color: var(--blue-600);
}
.blue:active {
    background-color: var(--blue-600);
}
.blue:focus {
    box-shadow: 0 0 0 2px var(--darkBlue-400);
}
