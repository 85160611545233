.paginator {
    width: 100%;
    margin-top: 21px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    padding-bottom: 50px;
}
