.show-more-btn {
    margin: 0;
    padding: 0;
    background-color: var(--grey-200);
    text-decoration: none;
    width: 100%;
    height: 34px;
    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    outline: none;
    border-radius: 3px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
    color: var(--darkBlue-700);
    mix-blend-mode: normal;
}

.paginator__show-more {
    margin-bottom: 20px;
}
.show-more-btn:active {
    background-color: var(--grey-400);
}
.show-more-btn:focus {
    border-color: var(--grey-400);
}
