.wrapper {
    position: relative;
}

.input {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.inner {
    margin: 2px;
    cursor: pointer;
    width: 36px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--whitebase);
    border: solid 4px var(--primary-400-base);
    position: relative;
}

.oval {
    width: 12px;
    height: 12px;
    background-color: var(--primary-400-base);
    border: 1px solid var(--whitebase);
    border-radius: 50%;
}

.input:checked ~ .inner {
    background-color: var(--primary-400-base);
}

.input:checked ~ .inner > .oval {
    background-color: var(--whitebase);
    transform: translateX(16px);
}

.input:focus ~ .inner {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.input:disabled ~ .inner {
    border-color: var(--black-100);
    cursor: auto;
}

.input:disabled ~ .inner > .oval {
    background-color: var(--black-100);
}
