.card {
    width: 217px;
    min-height: 308px;
    background-color: var(--primary-100);
    margin-bottom: 68px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.imageWrapper {
    width: 100%;
    height: 143px;
    overflow: hidden;
    background-color: var(--tertiary-200);
    position: relative;
}

.image {
    width: auto;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.marker {
    z-index: 1;
    position: absolute;
    top: 0;
    left: calc(50% - 23px);
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 46px solid rgba(0, 0, 0, 0.3);
}

.marker::after {
    content: "";
    top: -46px;
    left: -17px;
    position: absolute;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-top: 35px solid var(--error-400-base);
}

.marker::before {
    content: "";
    top: -46px;
    left: -20px;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 40px solid var(--whitebase);
}

.markerIcon {
    position: absolute;
    z-index: 2;
    top: -43px;
    left: -5px;
}

.info {
    padding-right: 22px;
    padding-left: 16.5px;
    padding-bottom: 8px;
}

.infoBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 35px;
    width: 100%;
    position: relative;
}

.infoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.infoIcon {
    width: 11px;
    height: 11px;
    fill: var(--secondary-400-base);
}

.infoData {
    padding: 0;
    margin: 0;
    font-family: Raleway, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-400-base);
}

.numberWrapper {
    position: absolute;
    bottom: 0;
    left: calc(50% - 33.5px);
    width: 67px;
    height: 67px;
    border-radius: 50%;
    border: solid 3px var(--whitebase);
    background-color: var(--primary-400-base);
    display: flex;
    justify-content: center;
    align-items: center;
}

.number {
    font-family: Raleway, sans-serif;
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 37px;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
    padding: 0;
    margin: 0;
    padding-bottom: 7px;
}

.numberWrapper--unreached {
    background-color: var(--whitebase);
}

.numberWrapper--unreached .number {
    color: var(--black-base);
}

.numberWrapper--inProgress {
    background-color: var(--warning-400-base);
}

.numberWrapper--overdue {
    background-color: var(--error-400-base);
}

.numberWrapper--completed {
    background-color: var(--success-400-base);
}

.title {
    margin: 0;
    margin-top: 15px;
    padding: 0;
    font-family: Raleway, sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
}

.description {
    padding: 0;
    margin: 0;
    margin-top: 8px;
    font-family: Raleway, sans-serif;
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
}

.iconDelWrapper {
    padding: 5px 16px;
    display: flex;
    justify-content: flex-end;
}
