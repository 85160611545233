.icon {
    position: absolute;
    left: -51px;
    top: 42px;
    fill: var(--whitebase);
    z-index: 110;
}

.popup {
    padding-top: 42px;
    padding-left: 74px;
    padding-right: 69px;
    padding-bottom: 47px;
    position: relative;
    box-sizing: border-box;
    width: 1204px;
    min-height: 500px;
    background-color: var(--whitebase);
    border-left: solid 64px var(--primary-400-base);
}

.title {
    margin: 0;
    padding: 0;
    font-family: Raleway, sans-serif;
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--black-base);
}

.scrolledWrapper {
    height: 100%;
    max-height: calc(80vh - 150px);
    overflow-y: auto;
    display: flex;
    margin: 10px 0;
}

.leftColumn {
    width: 401px;
}

.rightColumn {
    margin-left: 44px;
    margin-right: 10px;
    flex-grow: 1;
}

.lineWrapper {
    display: flex;
}

.lineWrapper > div:last-child {
    margin-left: 42px;
}

.selectWrapper {
    padding: 2px;
    width: 283px;
}
.uploadWrpper {
    margin-top: 84px;
}

.btnWrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}
