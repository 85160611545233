*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    font-family: Raleway, sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--darkBlue-700);
    font-weight: normal;
}

table {
    border-collapse: collapse;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

button:focus {
    outline: none;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

a {
    color: var(--blue-500);
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

label {
    margin: 0;
    display: inline-block;
}
input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

th {
    text-align: inherit;
}

@-webkit-keyframes autofill {
    to {
        color: var(--darkBlue-500);
        background: var(--grey-100);
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

@font-face {
    font-family: "Roboto Black";
    src: url("/fonts/roboto_black.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_black.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_black.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_black.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_black.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_black.svg#roboto_black") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Black Italic";
    src: url("/fonts/roboto_black_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_black_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_black_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_black_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_black_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_black_italic.svg#roboto_black_italic") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Bold";
    src: url("/fonts/roboto_bold.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_bold.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_bold.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_bold.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_bold.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_bold.svg#roboto_bold") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Bold Condensed";
    src: url("/fonts/roboto_bold_condensed.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_bold_condensed.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_bold_condensed.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_bold_condensed.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_bold_condensed.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_bold_condensed.svg#roboto_bold_condensed") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Bold Condensed Italic";
    src: url("/fonts/roboto_bold_condensed_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_bold_condensed_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_bold_condensed_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_bold_condensed_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_bold_condensed_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_bold_condensed_italic.svg#roboto_bold_condensed_italic")
            format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Bold Italic";
    src: url("/fonts/roboto_bold_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_bold_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_bold_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_bold_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_bold_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_bold_italic.svg#roboto_bold_italic") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Italic";
    src: url("/fonts/roboto_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_italic.svg#roboto_italic") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Light";
    src: url("/fonts/roboto_light.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_light.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_light.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_light.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_light.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_light.svg#roboto_light") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Light Italic";
    src: url("/fonts/roboto_light_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_light_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_light_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_light_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_light_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_light_italic.svg#roboto_light_italic") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Medium";
    src: url("/fonts/roboto_medium.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_medium.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_medium.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_medium.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_medium.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_medium.svg#roboto_medium") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Medium Italic";
    src: url("/fonts/roboto_medium_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_medium_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_medium_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_medium_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_medium_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_medium_italic.svg#roboto_medium_italic") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Regular";
    src: url("/fonts/roboto_regular.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_regular.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_regular.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_regular.svg#roboto_regular") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Thin";
    src: url("/fonts/roboto_thin.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_thin.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_thin.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_thin.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_thin.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_thin.svg#roboto_thin") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Roboto Thin Italic";
    src: url("/fonts/roboto_thin_italic.eot"); /* IE 9 Compatibility Mode */
    src: url("/fonts/roboto_thin_italic.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("/fonts/roboto_thin_italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/roboto_thin_italic.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("/fonts/roboto_thin_italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/fonts/roboto_thin_italic.svg#roboto_thin_italic") format("svg"); /* Chrome < 4, Legacy iOS */
}
