.button {
    width: 217px;
    height: 357px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: relative;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.icon {
    width: 70px;
    height: 70px;
    fill: var(--secondary-400-base);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:active .icon {
    fill: var(--secondary-500);
}

.button:disabled {
    cursor: auto;
}

.button:disabled .icon {
    fill: var(--black-100);
}
