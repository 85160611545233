.wrapper {
    margin: 0;
    display: flex;
    align-self: center;
}

.button {
    margin: 0;
    padding: 0;
    border-radius: 1px;
    background-color: var(--black-100);
    text-decoration: none;
    min-width: 24px;
    height: 24px;
    border: 1px solid var(--black-100);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    outline: none;
    margin-right: 4px;
    border-radius: 1px;
}

.buttonLeft::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border: 2px solid var(--black-base);
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);
    top: calc(50% - 4px);
    left: calc(50% - 4px);
}

.buttonRight::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border: 2px solid var(--black-base);
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
    top: calc(50% - 4px);
    left: calc(50% - 4px);
}

.text {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
}

.buttonActive {
    background-color: var(--primary-400-base);
}
.buttonActive .text {
    color: var(--whitebase);
}

.button:focus {
    border-color: var(--darkBlue-500);
}

.button:active {
    background-color: var(--primary-300);
}
.button:active .text {
    color: var(--whitebase);
}

.buttonLeft:active::before {
    border-color: var(--whitebase);
    border-top-color: transparent;
    border-right-color: transparent;
}

.buttonRight:active::before {
    border-color: var(--whitebase);
    border-bottom-color: transparent;
    border-left-color: transparent;
}
