.button {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: relative;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
    fill: var(--secondary-400-base);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:active .icon {
    fill: var(--secondary-500);
}

.button:disabled {
    cursor: auto;
}

.button:disabled .icon {
    fill: var(--black-100);
}
