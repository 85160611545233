.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mainTitle {
    width: 100%;
    border-bottom: solid 3px var(--primary-400-base);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-bottom: 6px;
    margin: 0;
    margin-top: 87px;
}

.titleIcon {
    width: 24px;
    height: 24px;
    fill: var(--primary-400-base);
}

.title {
    margin: 0;
    padding: 0;
    margin-left: 10px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: var(--black-base);
}

.cardWrapper {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
