.btn {
    border: none;
    background-color: transparent;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 12px;
    text-align: right;
    color: var(--blue-500);
    cursor: pointer;
    outline: none;
    padding: 0;
    border-radius: 4px;
    padding: 3px;
}

.btn:hover {
    color: var(--blue-700);
}

.btn:active {
    color: var(--blue-700);
    background-color: var(--grey-200);
}

.btn:focus {
    box-shadow: 0 0 0 2px var(--blue-500);
}
