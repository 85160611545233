.input {
    width: calc(100% - 4px);
    height: 242px;
    resize: none;
    border: solid 2px var(--black-100);
    background-color: transparent;
    outline: none;
    padding: 6px 14px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    margin-top: 4px;
    margin: 2px;
}

.input:disabled {
    background-color: var(--grey-100);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.input:placeholder {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-200);
}
