.filterItemSearch {
    width: 215px;
    margin-left: 15px;
}

.filterItemApply {
    margin-left: 24px;
}

.filterBtn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    height: 30px;
    width: 100%;
    border-radius: 3px;
    color: var(--darkBlue-500);
    cursor: pointer;
    font-family: var(--RM);
    font-size: 14px;
    font-variant: normal;
    font-weight: 500;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
    vertical-align: baseline;
    white-space: nowrap;
    position: relative;
    outline: none;
}

.filterBtn:focus {
    border-color: var(--blue-500);
}
.filterBtn:hover {
    color: var(--darkBlue-500);
}

.filterBtn:active {
    border-color: var(--blue-500);
    color: var(--darkBlue-500);
}

.filterBtnReset {
    color: var(--blue-500);
    font-weight: normal;
}

.filterBtnReset:hover {
    color: var(--blue-600);
}

.filterBtnReset:active {
    color: var(--blue-600);
}

.filterBtnApply {
    color: var(--white);
    background-color: var(--darkBlue-500);
    min-width: 81px;
    justify-content: center;
}

.filterBtnApply:hover {
    color: var(--white);
    background-color: var(--darkBlue-600);
}

.filterBtnApply:active {
    color: var(--white);
    background-color: var(--darkBlue-600);
}

.filterBtnActive {
    box-shadow: none;
    background: var(--darkBlue-600);
    color: var(--white);
    border: none;
}

.filterBtnActive:hover {
    color: var(--white);
    background-color: var(--darkBlue-600);
}

.filterBtnDropArrow {
    padding-right: 20px;
}

.filterBtnDropArrow::after {
    content: "";
    position: absolute;
    top: 11px;
    right: 5px;
    margin-left: -16px;
    width: 6px;
    height: 6px;
    border: none;
    border-right: 2px solid var(--darkBlue-500);
    border-top: 2px solid var(--darkBlue-500);
    transform: rotate(135deg);
}

.filterBtnWrap {
    display: block;
    width: 100%;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.hidden {
    display: none;
}

.icon {
    fill: var(--darkBlue-500);
}

.filterSearch {
    border: 1px solid var(--grey-500);
    box-sizing: border-box;
    border-radius: 3px;
    height: 30px;
    width: 100%;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 8px;
    padding-left: 8px;
    outline: none;
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: var(--darkBlue-500);
    background-color: transparent;
    padding-right: 40px;
    background-repeat: no-repeat;
    background-image: url("img/magnifier.svg");
    background-size: 16px;
    background-position: 95% 50%;
}

.filterSearchSubMenu {
    border: none;
    background-color: var(--white);
}

.filterSearchSubMenu::placeholder {
    opacity: 0.6;
}

.filterSearchSubMenu:focus {
    outline: none;
    background-color: var(--white);
    box-shadow: none;
    border-color: var(--white);
}

.filterSearch::placeholder {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: var(--darkBlue-500);
    opacity: 0.3;
    vertical-align: middle;
}

.subWrapper .filterBtn {
    padding-right: 40px;
}

.subWrapper .filterBtn::after {
    right: 25px;
}
