.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.navigationWrapper {
    margin-top: 47px;
}

.cardWrapper {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
