.wrapper {
    border-radius: 3px;
    overflow: auto;
    max-width: 100%;
    background: linear-gradient(to right, white 30%, var(--white)),
        linear-gradient(to right, var(--white), white 70%) 0 100%,
        linear-gradient(to right, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll;
}

.table {
    width: 100%;
    border-collapse: collapse;
}
.line:hover td {
    background-color: var(--grey-200);
}
.line:last-child td {
    border-bottom: 2px solid #dfe1e6;
}
