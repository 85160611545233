.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.signaturePad {
    width: 407px;
    height: 144px;
    border: solid 2px var(--primary-400-base);
}

.currentSignature {
    width: 407px;
    height: 144px;
    border: solid 2px var(--primary-400-base);
}

.buttonsWrapper {
    margin-top: -2px;
    display: flex;
    border: solid 2px var(--primary-400-base);
}

.noSignature {
    width: 407px;
    height: 144px;
    border: solid 2px var(--primary-400-base);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.noSignatureText {
    width: 302px;
    font-family: Raleway, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-200);
}
