.wrapper {
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.label {
    display: flex;
    margin-right: 20px;
    align-items: center;
    margin-bottom: 10px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
}

.input {
    width: 220px;
}

.search {
    margin-left: 5px;
    width: 200px;
    height: 36px;
    border-radius: 4px;
    border-radius: 6px;
    border: solid 1px var(--grey-400);
    outline: none;
    font-family: Raleway, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--black-base);
}

.search:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.buttonWrapper {
    display: flex;
    align-items: center;
    height: 38px;
}
