.icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(-50%, -50%);
    display: block;
    width: 12px;
    height: 12px;
    fill: var(--white);
}
