.cell {
    font-family: Raleway, sans-serif;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    color: var(--darkBlue-500);
    vertical-align: middle;
    text-align: left;
    height: 50px;
    padding: 0 10px;
    cursor: pointer;
}

.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    position: relative;
}
