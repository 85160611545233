.title {
    font-family: Raleway, sans-serif;
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 37px;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
}
