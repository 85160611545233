.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonWrapper {
    align-self: flex-end;
    position: relative;
}

.footerWrapper {
    margin-top: 1rem;
}
